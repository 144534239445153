import React from "react";

function ToggleButton({ isShown, handleHideShow }) {
  return (
    <div className="toggler-checkbox">
      <input type="checkbox" id="cbx-3" onChange={handleHideShow} checked={isShown} />
      <label htmlFor="cbx-3" className="toggle">
        <span></span>
      </label>
    </div>
  );
}

export default ToggleButton;
