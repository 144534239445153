import React, { useState, useEffect } from "react";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import LiveLinksHeader from "../LiveLinksHeader";
import { inter, poppins, oswald } from "../../utils/fonts";

const MainLayout = ({ children, showLiveLinkHeader = true, className = "" }) => {
  // const [isDomLoaded, setIsDomLoaded] = useState(false);
  // useEffect(() => {
  //   setIsDomLoaded(true);
  // }, []);

  return (
    <div className={`${inter.variable} ${poppins.variable} ${oswald.variable} ${className}`}>
      {/* {isDomLoaded && (
        <> */}
      <Header />
      {showLiveLinkHeader && <LiveLinksHeader />}
      {children}
      <Footer />
      {/* </>
      )} */}
    </div>
  );
};

export default MainLayout;
